@import 'node_modules/normalize-scss/sass/normalize/import-now';
@import 'node_modules/bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css");
@import './base/base';
@import './base/variables';

@import './layout/NavBar.scss';
@import './layout/BottomBar.scss';
@import './HomePage/HomePage.scss';
@import './AboutPage/DevSkill.scss';
@import './AboutPage/AboutPage.scss';
@import './ProjectsPage/ProjectsPage.scss';
@import './ProjectsPage/ProjectCard.scss'