@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100vw;
  font-family: 'Inter Variable', Arial, 'sans-serif';
  font-size: $fs-base;
  font-weight: 400;
  line-height: 1.5;
  // color: $clr-foreground;
  overflow: hidden;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

button:disabled {
  cursor: default;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

#main {
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1 1 0%;
  overflow: auto;
  margin-top: 76px;
  margin-bottom: 90px;
}
