.home-page {
  margin: 10%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: $screen-lg;

  .about-me {
    padding: 2rem;
  
    .name {
      color: $clr-primary; 
      font-weight: bold;
      font-size: $fs-5xl;
    }
  
    .title {
      color: $clr-primary; 
      font-size: $fs-2xl;
      margin-bottom: 3rem;
    }
  
    .about-line {
      font-size: $fs-2xl;
    }
  
    .brief-intro {
      color: $clr-secondary; 
      font-size: $fs-lg;
      margin-bottom: 3rem;
    }
  
    .resume {
      max-width: 15rem;
      font-size: $fs-lg;
      text-align: center;
      border: 2px solid $clr-primary;
      background-color: $clr-secondary;

      &:hover{
        background-color: lighten($color: $clr-secondary, $amount: 20);
      }
    }
  }
  
  .profile-pic {
    margin-top: 3rem;
    img {
      max-width: 25rem;
      max-height: 25rem;
      border-radius: 50%;
      border: 2px solid $clr-primary;

      @media screen and (max-width: $screen-md) {
        max-width: 15rem;
        max-height: 15rem;
      }
    }
    
  }

}