.nav-bar {
    font-size: clamp($fs-base,50vw,$fs-2xl);
    text-align: center;
    align-items: center;

    position: fixed;
    top: 0;
    background-color: $clr-background;
    
    display: flex;
    width: 100%;
    justify-content: center;
    
    a {
        text-decoration: none;
        color: $clr-primary;
        padding: 2rem;
        
        &:hover {
            color: $clr-secondary;
        }
    }

    a.active {
        color: $clr-secondary;
    }
    
    .theme-togger {
        padding: 2rem;
    }
}

@media (max-width: $screen-sm) {
    .nav-bar {
      font-size: $fs-base;

      a {
        padding: 1rem;
      }
    }
}