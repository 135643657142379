.about-page{
    padding: 2rem;
    width: 100%;
    max-width: $screen-lg;

    .experience {
        padding: 2rem;
        margin: auto;
        border: 1px solid $clr-background;
        border-radius: 5px;
        max-width: 50rem;
        text-align: center;
        justify-content: center;

        .text {
            color: $clr-primary;
            font-size: $fs-3xl;

            @media screen and (max-width: $screen-md) {
                font-size: $fs-lg;
            }
        }

        .year {
            font-size: $fs-3xl;

            @media screen and (max-width: $screen-md) {
                font-size: $fs-lg;
            }
        }

        .intro{
            color: $clr-secondary;
            font-size: $fs-lg;

            @media screen and (max-width: $screen-md) {
                font-size: $fs-base;
            }
        }
    }

    .text-skill {
        text-align: center;
        font-size: $fs-3xl;
        color: $clr-primary;
        padding: 2rem;
        margin: auto;

        @media screen and (max-width: $screen-md) {
            font-size: $fs-lg;
        }
    }

    .skills-container {
        margin: auto;
        padding: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3.6rem;
        width: 100%;
    }
}
