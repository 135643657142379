.dev-skill {
    display: block; /* Keep block-level display for card layout */
    border: 1px solid #ddd; /* Adjust border style and color as needed */
    padding: 1rem; /* Adjust padding for spacing within the card */
    border-radius: 0.5rem; /* Add rounded corners */
    width: 15rem; /* Set a fixed width for consistent card size */
    // min-height: 10rem; /* Set a fixed height for consistent card size */
    box-sizing: border-box; /* Include padding within the fixed height */
    text-align: center;

    .icon {
        font-size: $fs-5xl;
    }

    .name {
        font-size: $fs-lg;
    }

    .level {
        font-size: $fs-xs; 
    }
}

@media screen and (max-width: $screen-md) {
    .dev-skill {
        width: 12rem;

        .icon {
            font-size: $fs-3xl;
        }
    
        .name {
            font-size: $fs-base;
        }
    
        .level {
            font-size: 1rem;
        }
    }
    
}